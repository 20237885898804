<template>
  <vx-card>
    <vs-row>
      <h4 class="mb-5">Edit Airport</h4>
    </vs-row>

    <vs-row vs-w="6">
      <vs-col vs-w="12" class="mb-3">
        <span class="mb-2 block text-sm">City</span>
        <v-select
          label="name"
          :options="cities"
          :reduce="(airport) => airport.id"
          :dir="$vs.rtl ? 'rtl' : 'ltr'"
          v-model="payload.city_id"
        />
      </vs-col>
      <vs-col vs-w="12">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <vs-input class="w-full" label="Name (*)" v-model="payload.name" />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mt-3">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";
// Store Module
import airportStore from "@/store/airport";
import cityStore from "@/store/city-management/moduleCityManagement";

export default {
  name: "AirportEdit",

  metaInfo: {
    title: "Airport Feature",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    vSelect,
  },

  computed: {
    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    },
    cities() {
      return this.$store.state.city.cities || [];
    },
  },

  data: () => ({
    payload: {
      name: "",
      city_id: "",
    },
  }),

  methods: {
    async getData() {
      const response = await this.$store.dispatch(
        "airport/fetchAirport",
        this.decryptedID
      );
      this.payload.name = response.message.name;
      this.payload.city_id = response.message.city.id;
      this.$vs.loading.close();
    },

    async storeData() {
      const formData = new FormData();
      // set formData
      formData.set("name", this.payload.name);
      formData.set("city_id", this.payload.city_id);
      formData.append("_method", "PUT");
      const response = await this.$store.dispatch("airport/updateAirport", {
        id: this.decryptedID,
        body: formData,
      });
      if (!response.success) {
        this.$catchErrorResponse(response.message);
        return;
      }

      this.$vs.notify({
        title: "Successful",
        text: response.msg_status,
        iconPack: "feather",
        icon: "icon-check-circle",
        color: "success",
      });

      this.$router.push("/airport");
    },
  },

  async created() {
    if (!airportStore.isRegistered) {
      this.$store.registerModule("airport", airportStore);
      airportStore.isRegistered = true;
    }

    if (!cityStore.isRegistered) {
      this.$store.registerModule("city", cityStore);
      cityStore.isRegistered = true;
    }
    await this.$store.dispatch("city/fetchCities");
    this.getData();
  },
};
</script>

<style></style>
