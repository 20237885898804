import axios from '@/axios.js'

export default {
  fetchCities ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/cities')
        .then(({data: response}) => {
          commit('SET_CITIES', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchCity (context, cityId) {
    return new Promise((resolve, reject) => {
      axios.get(`/cities/${cityId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeCity (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post('/cities', payload)
           .then((res) => {
             resolve(res.data)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateCity (context, payload) {
    return new Promise((resolve, reject) => {
      const id = payload.id;
      delete payload.id;
      axios.post(`/cities/${id}`, payload)
           .then(res => {
             resolve(res.data)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyCity ({ commit }, cityId) {
    return new Promise((resolve, reject) => {
      axios.delete(`cities/${cityId}`)
        .then((response) => {
          commit('REMOVE_RECORD', cityId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
