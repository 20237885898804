import state from './moduleCityManagementState.js'
import mutations from './moduleCityManagementMutations.js'
import actions from './moduleCityManagementActions.js'
import getters from './moduleCityManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

